import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<strong parentName="p">{`Assembly of Vassals`}</strong>{` is a key political body in the city of `}<a parentName="p" {...{
        "href": "/Meripol",
        "title": "Meripol"
      }}>{`Meripol`}</a>{`, comprising elected officials who represent the city's various districts and neighborhoods. The Assembly plays a vital role in the city's decision-making process, working alongside the `}<a parentName="p" {...{
        "href": "/Chamber%20of%20Nobles",
        "title": "Chamber of Nobles"
      }}>{`Chamber of Nobles`}</a>{` to pass legislation and set policies that impact the lives of the city's citizens.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      